@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@1,700&family=Montserrat:ital,wght@1,600;1,900&display=swap');

.App {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: space-around;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  background-color:  #3339;
  overflow-y: hidden;
}

.date{
  position: fixed;
  display: inline;

  
  left: 8vw;
  top: 8vh;


  width: 350px;
  height: 20vh;

  user-select: none;
}


.date .content{
  position: relative;
  top:0;
  left: 0;
  font-size: 72px;
  text-align: left;

  user-select: none;
  cursor: pointer;
  z-index: 1000;


  color: white;
  appearance: none;
  border: none;
  background-color: #0000;
}


.date option{
  color: black;
  font: arial;
  font-size: 16px !important;
  font-weight: normal;
}

#day{
  padding:0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 6rem;
  width: fit-content;
}

#month{
  font-size: 5rem;
  font-family: 'IBM Plex Serif', serif;
  font-weight: 700;
  padding: 0;
}

.slash{
  position: relative;
  display: inline-block;
  font-size: 3rem;
  font-family: 'IBM Plex Serif', serif;
  font-weight: 700;
  color: white;
  z-index: 100;
  left: -5px;
  margin-right: 5px;
}

.stroke{
  width: 100%;
  position: absolute;
  top: 3px;
  left: -2px;
  margin:0;
  -webkit-text-stroke: 28px #000000;
  -webkit-text-fill-color: transparent;
  pointer-events: none;
  z-index: -50 !important;
}

.stroke span{
  margin: 2px;
}

.background{
  position: fixed;
  z-index: -1000;
  top:20;
  width: 100%;
}

.card {
  margin: 25vh 0 15vh 0;
  max-width: 100vw;
  height: fit-content;

  user-select: none;
  
}

.cardList{
  display: flex;
  flex-direction: column;
  width: 60vw;
  height: 100vh;
  
  overflow-x: hidden;
  box-sizing: border-box;
  
}

.cardList section{
  overflow-x: hidden;
  overflow-y: auto;
  height: 90%;
  margin: 0;

  padding-top: 5vh;
  padding-bottom: 5vh;


  scrollbar-width: none; 
}

.cardList section::-webkit-scrollbar {
  display: none;
}

.button{
  --offset: 10%;
  position: fixed;
  top:45%;

  padding: 10px 30px;
  background-color: red;
  border: black 9px solid;
  font-size: 28px;
  font-weight: 800;

  user-select: none;
  cursor: pointer;

}

.button:hover{
  scale: 1.3;
  transition: scale .3s;
}


.right{
  transform: perspective(200px) rotateY(-20deg);
  right: var(--offset);
}

.left{
  transform: perspective(200px) rotateY(20deg);
  left: var(--offset);
}

.scrollIndicator{
  position: fixed;
  float: right;
  right: 10%;
  bottom: 10%;
  z-index: 1000;
  text-align: center;
  font-size: 24px;
  width: 150px;
  --padding: 0;
  padding: var(--padding);
}

#mask{
  width: 100%;
  max-width: 130px;
  margin: auto;
  margin-top: -20px;
  animation: spin 3s ease-in-out infinite;
}

.scrollIndicator .stroke{
  position: absolute;
  top:0;
  left:0;
  right:0;
  -webkit-text-stroke: 10px black;
  z-index: -5;
  padding: var(--padding);

}

.scrollIndicator h2{
  margin: 0;
  margin-bottom: 20px;
}

.scrollIndicator svg{
  margin: 0% auto;
  margin-top: -20%;
  margin-bottom: -50%;
  width: 75px;
  display: block;
  animation: lightup linear infinite .99s;
  animation-delay: var(--delay);
}


/* Phones ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
  .right{
    right: -3vw;
  }
  
  .left{
    left: -3vw;
  }

  .date{

    scale: .7;
    top: 20px;
    left:0;
    right:0;


    margin: 0 auto;

    position: absolute;
    align-items: center;

    text-align: center;
    
  }
  .stroke #month {
    margin-left: -4px;
  }

  .stroke{
    width: 100%;
  }


  

  .background{
    position:fixed;
    width: auto;
    height: 100%;
    margin-left: -25%;
  }

  figure{
    overflow: hidden;
}
  .card {
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 0;
    height: 35vh;
    max-width: 100vw;
    
    
  }

  .cardList{
    margin-top: 0;
    max-width: 100vw;
    width: 100vw;
    padding-top: 18vh;


    overflow: hidden;
  }

  .cardList section{
    overflow-y: scroll;
    overflow-x: hidden;

    height: 100%;
  }


  .button{
    top: 7vh;
    scale: .7;
    z-index: 2001;
  }

  .button:hover{
    scale: .7;
  }

  .scrollIndicator{
    scale: .75;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -10px;
    --padding: 20px 0;
  }

  .cardList section{
    padding-top: 5vh;
    padding-bottom: 20vh;
  }

  .date option{
    font-size: 22px !important;
    scale: 1;
  }


}
/* Tablet (landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {

  

}

/* Tablet (portrait) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {

}


@keyframes spin{
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes lightup{
  0%, 100%,5%,95% {
    fill: #fff;
  }
  50% {
    fill: #f22;
  }
}


@keyframes dropdown {
  from{
    translate: 0 -100%;
    visibility: visible;
  }

  to{
    translate: 0;
    
  }
  
}